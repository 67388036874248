<template>
<div class="Templater">
  
  <template
    v-for="(block) in match[$route.name].body" :key="block.id"
  >
    
    <!-- caption -->
    <div
      v-if="block.type === 'caption'"
      class="Templater__caption"
      :style="block?.params?.style"
    >
      {{ block.content }}
    </div>
    
    <!-- paragraph -->
    <div
      v-if="block.type === 'paragraph'"
      class="Templater__paragraph"
      :style="block?.params?.style"
    >
      {{ block.content }}
    </div>
    
    <!-- step -->
    <div
      v-if="block.type === 'step'"
      class="Templater__step"
      :style="block?.params?.style"
    >
      <div class="Templater__step-label">
        {{ block.content }}
      </div>
    </div>
    
    <!-- note -->
    <div
      v-if="block.type === 'note'"
      class="Templater__note"
      :style="block?.params?.style"
    >
      {{ block.content }}
    </div>
    
    <!-- item -->
    <div
      v-if="block.type === 'item'"
      class="Templater__item"
      :style="block?.params?.style"
    >
      {{ block.content }}
    </div>
    
    <!-- download -->
    <div
      v-if="block.type === 'download'"
      class="Templater__download"
    >
      <a-button
        class="ant-btn-huge Templater__download-button"
        type="primary"
        size="large"
        :loading="!block.params.url"
        @click="openPdf(block.params.url)"
      >
        Tap to view
      </a-button>
      <img
        :src="getImgUrl(block.content)"
      >
    </div>
    
    <!-- image -->
    <div
      v-if="block.type === 'image'"
      class="Templater__image"
    >
      <img
        :style="block?.params?.style"
        :src="getImgUrl(block.content)"
      >
    </div>
    
    <!-- checkbox -->
    <a-checkbox
      v-if="block.type === 'checkbox'"
      class="ant-checkbox-green Templater__checkbox"
      :style="block?.params?.style"
      v-model:checked="check"
      size="large"
    >
      {{ block.content }}
    </a-checkbox>

    <component 
      v-if="block.type === 'component'"
      :is="block.content"
    >
    </component>
  </template>
  <a-button
    class="ant-btn-huge Templater__submit"
    type="green"
    size="large"
    :disabled="(isCheck ? !check : false)"
    @click="goNext"
    v-if="match[$route.name].button"
  >
    {{ match[$route.name].button }}
  </a-button>
  
</div>
</template>

<script>
export default {
  name: "Templater",
  props: {
    match: {
      type: Object,
      required: true,
    }
  },
  data: function () {
    return {
      check: false,
      hideNext: false,
    }
  },
  computed: {
    isCheck() {
      return this.match[this.$route.name].body ? !!this.match[this.$route.name].body.find(i => i.type === 'checkbox') : false
    },
  },
  methods: {
    getImgUrl(img) {
      return require(`@/assets/img/${img}`)
    },
    openPdf(url) {
      window.open(url, '_blank')
    },
    goNext() {
      this.check = false
      this.$emit('onSubmit')
    },
  },
}
</script>

<style lang="scss" scoped>
.Templater {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 15px;

  &__caption {
    color: $primary10;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: -10px;
  }
  
  &__paragraph {
    color: $primary10;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  
  &__step {
    color: $primary10;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: -10px;
    
    &-label {
      display: inline;
      border-bottom: 3px solid #86BC25;
    }
  }
  
  &__note {
    color: $neutral8;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-left: 2px solid $polarGreen7;
    padding-left: 0.8em;
    margin-left: 0.1em;
  }
  
  &__item {
    margin-left: 1em;
    color: $primary10;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    
    &::before {
      content: "\2022";
      color: $polarGreen7;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
  
  &__image {
    background: $neutral4;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  
  &__submit {
    margin-top: 10px;
  }
  
  &__download {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &-button {
      position: absolute;
      width: 160px;
    }
    img {
      width: 100%;
    }
  }
  
}
</style>